import React  from 'react';
import NumberFormat from 'react-number-format';

import tokenIcon from '../assets/img/loading.svg';
import closeIcon from '../assets/img/close.svg';

const SetStakeModal = ({
       hideStakeModal,
       stake,
       minStake,
       maxStake,
       id,
       setStakeVal,
       stakeVal,
       stakeReward,
       packageItem,
       setStakPrice,
   }) => {

    const calculateReward = (amount, apy, lockUp) => {
        let calculatedReward_ = 0;

        if (isNaN(amount) || amount < minStake || amount > maxStake) {
            calculatedReward_ = 0;
        } else {
            amount = parseInt(amount);
            setStakPrice(amount);
            if(lockUp !== false){
                calculatedReward_ = stakeReward(amount*(10**18), apy, lockUp);
            }else{
                calculatedReward_ = (amount*apy).toFixed(2);
            }
        }
        setStakeVal(calculatedReward_);
    }

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-indigo-900 bg-opacity-80 transition-opacity" aria-hidden="true"> </div>
                <div className="inline-block border-indigo-600 border-2 rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
                    <div className="bg-indigo-800">
                        <div className="modal-header py-6 px-8 flex justify-between items-center gap-x-2">
                            <div className="flex items-center gap-x-2">
                                <img src={tokenIcon} alt="" className="w-10" />
                                <h2 className="text-3xl font-bold opacity-90">$WEAR Staking</h2>
                            </div>
                            <button type="button"
                                    onClick={() => hideStakeModal()}
                                    className="px-4 py-2 text-base font-medium text-gray-700 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                                <img src={closeIcon} className="w-10" alt=""/>
                            </button>
                        </div>
                        <div className="modal-body px-24 pb-8 pt-2">
                            <p className="text-gray-300 text-lg text-center mb-10">Staking $WEAR doesn’t require you to lock up your tokens. However, withdrawing tokens before the end of chosen staking period results in a penalty depending on the number of days prior to maturity.</p>
                            <div className="stake-form mb-9">
                                <div className="flex items-start gap-x-2">
                                    <div className="w-full">
                                        <div className="overflow-hidden flex bg-white rounded-md w-full pl-3 text-indigo-900 focus:outline-none focus:shadow-outline">
                                            <img className="w-8" src={tokenIcon} alt=""/>
                                            <input type="text"
                                                   id={`stakeValue${id}`}
                                                   onChange={(e) => {
                                                       setStakeVal(e.target.value);
                                                       calculateReward(e.target.value, packageItem.APY, packageItem.lockUp)
                                                   }}
                                                   name="stake_count"
                                                   placeholder="stake amount"
                                                   autoComplete="off"
                                                   className="appearance-none border-none h-14 text-2xl w-full py-4 px-3 text-indigo-900 placeholder-indigo-300 focus:outline-none"/>
                                        </div>
                                        { (""+stakeVal).trim()!='' && stakeVal == 0 ?
                                            <NumberFormat
                                                value={minStake}
                                                className="text-rose-500 mt-3.5 text-sm block"
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value, props) =><span {...props}>Deposit must be between: {value}
                                                    <NumberFormat
                                                        value={maxStake}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        renderText={(value, props) =><span {...props}> and {value} $WEAR</span>}
                                                    />
                                                </span>}
                                            />
                                            : ""}

                                    </div>
                                    <button disabled={ stakeVal > 0 ? '' : 'disabled'} className={`${ stakeVal > 0 ? 'hover:bg-cyan-500' : 'opacity-50 cursor-not-allowed'} bg-cyan-400 h-14 text-indigo-900 text-2xl font-medium py-3 px-7 rounded-md focus:outline-none`}
                                            onClick={stake}>Stake
                                    </button>
                                </div>
                            </div>
                            <div className="stake-details bg-indigo-900 card rounded-xl w-3/4 mx-auto">
                                <div className="card-header p-4">
                                    <h3 className="uppercase text-gray-300 opacity-50 font-medium">details</h3>
                                </div>
                                <div className="card-body px-9 pt-2 pb-8 flex flex-col gap-y-3">
                                    {packageItem.lockUp !== false
                                    ?
                                    <>
                                        <div className="flex justify-between items-center">
                                            <h4 className="font-medium text-base text-gray-300">Period:</h4>
                                            <span className="text-cyan-500">{packageItem.date}</span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <h4 className="font-medium text-base text-gray-300">Income Rate:</h4>
                                            <span className="text-cyan-500">{packageItem.APY}%</span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <h4 className="font-medium text-base text-gray-300">Total Return:</h4>
                                            <span className="text-cyan-500">{(""+stakeVal).trim()!='' && stakeVal > 0 ? stakeVal + ' $WEAR' : '-'}</span>
                                            {/*<NumberFormat
                                                value={stakeVal}

                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value, props) =><span {...props}>{(""+value).trim()!='' && value > 0 ? value + ' WEAR' : '-'}</span>}
                                            />*/}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="flex justify-between items-center">
                                            <h4 className="font-medium text-base text-gray-300">Period:</h4>
                                            <span className="text-cyan-500">{packageItem.date}</span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <h4 className="font-medium text-base text-gray-300">Income Rate:</h4>
                                            <span className="text-cyan-500">{packageItem.apyName}</span>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <h4 className="font-medium text-base text-gray-300">Reward per hour:</h4>
                                            <span className="text-cyan-500">{(""+stakeVal).trim()!='' && stakeVal > 0 ? stakeVal + ' $WEAR' : '-'}</span>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetStakeModal;