import React from 'react';

const Footer = () => {
    return (
        <div className="py-4 bg-indigo-400 main-bg-color-900">
            <div className="text-center">
                © Metaversewear.io | All rights reserved.
            </div>
        </div>
    )
}

export default Footer;