import React from "react";
import NumberFormat from "react-number-format";

const UnstakeConfirmModal = ({
    claim,
    claimAll,
    hideUnstakeModal,
    penaltyPrice,
    claimItem,
    packageItem
}) => {
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div className="fixed inset-0 bg-indigo-900 bg-opacity-80 transition-opacity" aria-hidden="true"> </div>
                <div className="inline-block border-indigo-600 border-2 rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-xs sm:w-full">
                    <div className="bg-indigo-800">
                        <div className="modal-body px-6 py-8 gap-y-5 flex flex-col items-center">
                            <NumberFormat
                                value={penaltyPrice}
                                className="text-base text-md"
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={(value, props) =><span {...props}>If you confirm, you will unstake with {value.replace(/[,]+/g, '')} $WEAR {packageItem.lockUp === false ? 'SuperPool Service Cost' : 'penalty'}. Are you sure?</span>}
                            />
                            <div className="flex items-center justify-center gap-x-2">
                                <button type="button" onClick={() => hideUnstakeModal()} className="bg-indigo-900 text-gray-300 hover:bg-indigo-600 py-2 px-4 rounded-md focus:outline-none focus:shadow-outline">Cancel</button>
                                {packageItem.lockUp !== false ? 
                                <button type="button" onClick={() => claim(claimItem, 2)} className="bg-cyan-500 text-indigo-900 hover:bg-cyan-400 py-2 px-4 rounded-md focus:outline-none focus:shadow-outline">Confirm</button>
                                :
                                <button type="button" onClick={() => claimAll(claimItem, 2)} className="bg-cyan-500 text-indigo-900 hover:bg-cyan-400 py-2 px-4 rounded-md focus:outline-none focus:shadow-outline">Confirm</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnstakeConfirmModal;