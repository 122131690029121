import React, {useState} from 'react';
import JsxParser from 'react-jsx-parser';
import '../assets/css/accordion.css';
import arrowUp from '../assets/img/arrow-up.svg';
import arrowDown from '../assets/img/arrow-down.svg';

const Accordion = () => {
    const [selected, setSelected] = useState(null);
    const toggle = (i) => {
        if(selected === i) {
            return setSelected(null)
        }
        setSelected(i);
    }
    return (
        <div className="wrapper">
            <div className="accordion">
                {data.map((item, i) => (
                    <div className="item mb-3" key={i}>
                        <div className="title bg-indigo-900 hover:bg-indigo-400 rounded-md py-6 px-8 flex items-center justify-between main-bg-color-900"
                            onClick={() => toggle(i)}>
                            <h2 className="font-medium text-lg">{item.question}</h2>
                            <img src={selected === i ? arrowUp : arrowDown} alt=""/>
                        </div>
                        <div className={`text-base text-gray-300 ${selected === i ? 'content show' : 'content'}`}>
                            <div className="py-6">
                                <JsxParser
                                    jsx={item.answer}
                                />

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}


const data = [
    {
        question: 'How does it work?',
        answer: 'You can stake from 2K to 1M $WEAR and receive respective compounded interest based on your chosen staking pool.',
    },
    {
        question: 'What are the staking options?',
        answer: `We have four staking options offering different time periods: 
            <ul className="list-circle pl-9"> 
                <li>Diamond Pool: 18 months</li>
                <li>Platinum Pool: 14 months</li>
                <li>Golden Pool: 12 months</li>
                <li>Silver Pool: 6 months</li>
                <li>Bronze Pool: 3 months</li>
            </ul> 
            When you stake by choosing a specific time frame, you’ll receive the respective Income Rate based on that specified pool.`
    },
    {
        question: 'How much is the Income Rate, effective rate and monthly gain for each period?',
        answer: `The longer the staking period is, the higher the rewards are.
            <ul className="list-circle pl-9">
                <li className="mt-2">18 months (Diamond Pool):
                    <ul className="list-disc pl-9">
                        <li>Income Rate: 145%</li>
                    </ul>
                </li>
            <li className="mt-2">14 months (Platinum Pool):
                <ul className="list-disc pl-9">
                    <li>Income Rate: 120%</li>
                </ul>
            </li>
            <li className="mt-2">12 months (Golden Pool):
                <ul className="list-disc pl-9">
                    <li>Income Rate: 80%</li>
                </ul>
            </li>
            <li className="mt-2">6 year (Silver Pool):
                <ul className="list-disc pl-9">
                    <li>Income Rate: 35%</li>
                </ul>
            </li>
            <li className="mt-2">3 year (Bronze Pool):
                <ul className="list-disc pl-9">
                    <li>Income Rate: 12%</li>
                </ul>
            </li>
            </ul>`,
    },
    {
        question: 'Is there any lockup period or penalty?',
        answer: `Staking WEAR doesn’t require you to lock up your tokens. You can withdraw your tokens at any time you wish. However, it comes with a cost. Withdrawing the assets before the end of chosen staking period results in a penalty depending on the number of days prior to maturity.
            <p className="mt-2"><i>There’s a 10% penalty percentage for all staking pools, and it decreases linearly towards zero, day by day until maturity.</i></p>`
    },
    {
        question: 'Who can stake?',
        answer: 'Anyone holding a minimum of 2K $WEAR in their wallet can stake via our Staking Platform on desktop.'
    },
    {
        question: 'Which wallets can I use to stake $WEAR',
        answer: `$WEAR staking is currently only possible via 
         <a href="https://metamask.io/" target="_blank" rel="noreferrer" className="underline text-cyan-500">Metamask</a>
         platform on desktop.`
    },
    {
        question: 'Is there any limit on the staking amount?',
        answer: `We have minimum and maximum limits for each staking pool (in $WEAR).
            <ul className="list-circle pl-9">
                <li className="mt-2">Diamond pool (18 months):
                    <ul className="list-disc pl-9">
                        <li>Minimum: 2k WEAR</li>
                        <li>Maximum: 1M WEAR</li>
                    </ul>
                </li>
                <li className="mt-2">Platinum pool (14 months):
                    <ul className="list-disc pl-9">
                        <li>Minimum: 2k WEAR</li>
                        <li>Maximum: 1M WEAR</li>
                    </ul>
                </li>
                <li className="mt-2">Golden pool (12 months):
                    <ul className="list-disc pl-9">
                        <li>Minimum: 2k WEAR</li>
                        <li>Maximum: 750k WEAR</li>
                    </ul>
                </li>
                <li className="mt-2">Silver pool (6 months):
                    <ul className="list-disc pl-9">
                        <li>Minimum: 2k WEAR</li>
                        <li>Maximum: 500k WEAR</li>
                    </ul>
                </li>
                <li className="mt-2">Bronze pool (3 months):
                    <ul className="list-disc pl-9">
                        <li>Minimum: 2k WEAR</li>
                        <li>Maximum: 250k WEAR</li>
                    </ul>
                </li>
            </ul>`,
    },
    {
        question: 'What are the maximum capacities of each pool?',
        answer: `Staking pools’ capacities are limited. It means if the amount of staked tokens in one pool in total (by others, as well) has reached the pool’s capacity limit, the pool will become unavailable for everyone to stake more. Capacities are as follows (in $WEAR):
            <ul className="list-circle pl-9">
                <li className="mt-2">Diamond Pool: 20M WEAR</li>
                <li className="mt-2">Platinum Pool: 20M WEAR</li>
                <li className="mt-2">Golden Pool: 15M WEAR</li>
                <li className="mt-2">Silver Pool: 10M WEAR</li>
                <li className="mt-2">Bronze Pool: 5M WEAR</li>
            </ul>`
    },
];

export default Accordion;