import React from 'react';
import NumberFormat from "react-number-format";

import gateButtonImg from "../assets/img/Gate-Button.png";
import pcsButtonImg from "../assets/img/PCS-Button.png";


const GetWear = ({balancePackage, SubTotalBalance, SubTotalEarnings, isLogin, networkIsTrue}) => {
    return (
        <div className="grid grid-cols-3 gap-x-14 gap-y-10 mb-9">
            <div className="card rounded-xl main-bg-color-900">
                <div className="card-header p-4">
                    <h3 className="uppercase text-gray-300 opacity-50 font-medium uppercase">DEX</h3>
                </div>
                <div className="card-body px-9 pt-2 pb-6 flex flex-col items-center justify-between">
                    <div className="flex flex-col items-center gap-y-3">
                        <img src={pcsButtonImg} alt=""/>
                    </div>
                    <div className="flex">
                        <a href="https://pancakeswap.finance/info/token/0x9d39eF3bbCA5927909dde44476656B81BBe4eE75"
                           target="_blank"
                           rel="noreferrer"
                           className="bg-gray-300 hover:bg-slate-400 text-base text-indigo-900 font-semibold px-3 py-1 my-2 rounded-md text-center mr-1.5">Get $WEAR
                        </a>
                        <a href="#stake" className="text-indigo-900 bg-gray-300 hover:bg-slate-400 text-base font-semibold px-3 py-1 my-2 rounded-md text-center ml-1.5">Stake $WEAR</a>
                    </div>
                </div>
            </div>
            <div className="card rounded-xl main-bg-color-900">
                <div className="card-header p-4">
                    <h3 className="uppercase text-gray-300 opacity-50 font-medium uppercase">CEX</h3>
                </div>
                <div className="card-body px-9 pt-2 pb-6 flex flex-col items-center justify-between">
                    <div className="flex flex-col items-center gap-y-3">
                        <img src={gateButtonImg} alt=""/>
                    </div>
                    <div className="flex">
                        <a href="https://www.gate.io/trade/WEAR_USDT"
                           target="_blank"
                           rel="noreferrer"
                           className="bg-gray-300 hover:bg-slate-400 text-base text-indigo-900 font-semibold px-3 py-1 my-2 rounded-md text-center mr-1.5">Get $WEAR
                        </a>
                        <a href="#stake" className="text-indigo-900 bg-gray-300 hover:bg-slate-400 text-base font-semibold px-3 py-1 my-2 rounded-md text-center ml-1.5">Stake $WEAR</a>
                    </div>
                </div>
            </div>
            <div className="card rounded-xl main-bg-color-900">
                <div className="card-header p-4">
                    <h3 className="uppercase text-gray-300 opacity-50 font-medium uppercase">Dashboard</h3>
                </div>
                <div className="card-body px-9 pt-2 pb-6 flex flex-col gap-y-3">
                    <div className="flex justify-between items-center">
                        <h4 className="font-medium text-base text-gray-300">Total Value Locked:</h4>
                        <NumberFormat
                            value={(SubTotalBalance).toFixed()}
                            className="text-cyan-500"
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value, props) =><span {...props}>{value + ' $WEAR'}</span>}
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        <h4 className="font-medium text-base text-gray-300">Available Balance:</h4>
                        <NumberFormat
                            value={balancePackage}
                            className="text-cyan-500"
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value, props) =><span {...props}>{ isLogin && networkIsTrue ? value + ' $WEAR' : '-' }</span>}
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        <h4 className="font-medium text-base text-gray-300">Total Earnings:</h4>
                        <NumberFormat
                            value={SubTotalEarnings}
                            className="text-cyan-500"
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={(value, props) =><span {...props}>{ isLogin && networkIsTrue ? value + ' $WEAR' : '-' }</span>}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetWear;