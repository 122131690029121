import React, {useState, useEffect} from 'react';
import NumberFormat from 'react-number-format';

import UnstakeConfirmModal from "./UnstakeConfirmModal";

const FindStakerTable = ({
     showHideButtons,
     stakerInfo,
     packageItem,
     convertTime,
     stakeReward,
     stakePenalty,
     stakeStablePenalty,
     claim,
     claimAll,
     setUnstakeConfirm,
     unstakeConfirm,
     remainingDays,
     isLogin,
     networkIsTrue,
     tableInlineLoading
}) => {

    const [penaltyPrice, setPenaltyPrice] = useState("0");
    const [claimItem, setClaimItem] = useState("0");

    const showUnstakeModal = (pp, dd) => {
        setPenaltyPrice(pp);
        setClaimItem(dd);
        setUnstakeConfirm(true);
    };


    const hideUnstakeModal = () => {
        setUnstakeConfirm(false);
    };

    return (
    <>
    {isLogin && networkIsTrue && stakerInfo.length ?
    
        <div className="py-4 px-14 mx-auto w-4/5 overflow-hidden bg-indigo-400 rounded-b-2xl">
            <div className="overflow-x-auto">
                <div className="align-middle inline-block min-w-full">
                    <div className="overflow-hidden">
                        <table className="min-w-full stake-table">
                            <thead>
                                <tr>
                                    <th className="py-3 text-left text-xs font-medium text-cyan-500 uppercase">Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-cyan-500 uppercase">Deposit</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-cyan-500 uppercase">Reward</th>
                                    {packageItem.lockUp !== false ?
                                    <th className="px-6 py-3 text-left text-xs font-medium text-cyan-500 uppercase">Remaining days</th>
                                    : null}
                                    <th className="relative py-3"><span className="sr-only">Edit</span></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                stakerInfo.map(item =>
                                    <tr className="border-b border-indigo-600 border-solid text-sm text-gray-300 font-light"
                                        key={item.stakedAt+Math.floor(Math.random() * 100)} >
                                        <td className="py-3">
                                            <span>{convertTime(item.stakedAt)}</span>
                                        </td>
                                        <td className="px-6 py-3">
                                            <NumberFormat
                                                value={(item.amount / (10**18)).toFixed()}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value, props) =><span {...props}>{value} $WEAR</span>}
                                            />
                                        </td>
                                        <td className="px-6 py-3">
                                            <NumberFormat
                                                value={
                                                    item.claimable
                                                    ? (item.claimable / (10**18)).toFixed() 
                                                    : item.reward
                                                        ? ((item.reward / (10**18)).toFixed())
                                                        : 0
                                                    }
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                renderText={(value, props) =><span {...props}>{value} $WEAR</span>}
                                            />
                                        </td>
                                        {packageItem.lockUp !== false ?
                                        <td className="px-6 py-3">
                                            <span>{remainingDays(item.stakedAt, packageItem.dateSecond) > 0 ? remainingDays(item.stakedAt, packageItem.dateSecond) + ' days' : '-'}</span>
                                        </td> : null }
                                        <td className="py-3 text-right text-sm font-medium">
                                            {!item.claimedAt && packageItem.lockUp !== false ? 
                                            <div className="flex gap-x-2 justify-end">
                                                <button className={`bg-indigo-800 text-gray-300 hover:bg-indigo-600 py-1 px-3 rounded-md focus:outline-none focus:shadow-outline ${showHideButtons(1, packageItem.dateSecond, item.stakedAt)}`}
                                                        onClick={() => showUnstakeModal(stakePenalty(item.amount, item.stakedAt, packageItem.dateSecond, packageItem.penaltyPercentage, packageItem.divideValue), item.stakedAt)}>Unstake</button>
                                                <button className={`bg-cyan-500 text-indigo-900 hover:bg-cyan-400 py-1 px-3 rounded-md focus:outline-none focus:shadow-outline ${showHideButtons(2, packageItem.dateSecond, item.stakedAt)}`}
                                                        id={item.stakedAt}
                                                        onClick={() => claim(item.stakedAt, 1)}>Claim</button>
                                            </div>
                                            :
                                            <div className="flex gap-x-2 justify-end">
                                                <button className={`bg-indigo-800 text-gray-300 hover:bg-indigo-600 py-1 px-3 rounded-md focus:outline-none focus:shadow-outline`}
                                                        onClick={() => showUnstakeModal(stakeStablePenalty(item.amount, packageItem.penaltyPercentage), item.stakedAt)}>Unstake</button>
                                                <button className={`bg-cyan-500 text-indigo-900 hover:bg-cyan-400 py-1 px-3 ml-2 rounded-md focus:outline-none focus:shadow-outline`}
                                                id={item.stakedAt}
                                                onClick={() => claim(item.stakedAt, 1)}>Claim</button>
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    : isLogin && networkIsTrue && tableInlineLoading ? <div className="py-4 px-14 mx-auto w-4/5 overflow-hidden bg-indigo-400 rounded-b-2xl text-center">Loading...</div> : ''}

    {unstakeConfirm && penaltyPrice > 0 ?
        <UnstakeConfirmModal
            hideUnstakeModal={hideUnstakeModal}
            claim={claim}
            claimAll={claimAll}
            stakerInfo={stakerInfo}
            stakePenalty={stakePenalty}
            packageItem={packageItem}
            penaltyPrice={penaltyPrice}
            claimItem={claimItem}
        /> : ''}
    </>
    )
}

export default FindStakerTable;

