export const PackagesDataOld = [
    {
        id: 7,
        name: "Bronze Pools 2023",
        date: "3 months",
        lockUp: 3,
        APY: 15,
        dateSecond: 3*30*24*3600,
        penaltyPercentage: 10,
        divideValue: 3*30,
        minStake: 20000,
        maxStake: 500000,
        poolMax: 20000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0x9dbd77c794d346533737fddd17825e094f69ff5a",
        fileJson: 'Staking_3.json',
        active: 0
    }, 
    {
        id: 8,
        name: "Silver Pools 2023",
        date: "6 months",
        lockUp: 6,
        APY: 35,
        dateSecond: 6*30*24*3600,
        penaltyPercentage: 10,
        divideValue: 6*30,
        minStake: 20000,
        maxStake: 1000000,
        poolMax: 30000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0xc7b8e423b255968ac57cf22962da025733792d2f",
        fileJson: 'Staking_6.json',
        active: 0
    },
    
    {
        id: 9,
        name: "Golden Pools 2023",
        date: "12 months",
        lockUp: 12,
        APY: 80,
        dateSecond: 12*30*24*3600,
        penaltyPercentage: 10,
        divideValue: 12*30,
        minStake: 20000,
        maxStake: 2000000,
        poolMax: 50000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0x0c1c61b694e43523e784943cd5c998b1e4266fca",
        fileJson: 'Staking_12.json',
        active: 0
    },
    {
        id: 10,
        name: "Diamond Pools 2023",
        date: "18 months",
        lockUp: 18,
        APY: 120,
        dateSecond: 18*30*24*3600,
        penaltyPercentage: 10,
        divideValue: 18*30,
        minStake: 20000,
        maxStake: 3000000,
        poolMax:  200000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0xb20e122adcc0a06aaec69c7bced5b574586b91d3",
        fileJson: 'Staking_18.json',
        active: 0
    },
    {
        id: 6,
        name: "Super Pool",
        date: "Unlimited",
        lockUp: false,
        APY: 0.0001,
        apyName: '~246% COMPOUND',
        dateSecond: 3600,
        penaltyPercentage: 1.7,
        divideValue: 1,
        minStake: 2000,
        maxStake: 1000000,
        poolMax: 20000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0x649906565fea329E09e0cc212F3ffF6f7f6B8645",
        fileJson: 'StakingSuperPool.json',
        active: 0
    },
    {
        id: 5,
        name: "Diamond Pools",
        date: "18 months",
        lockUp: 18,
        APY: 145,
        dateSecond: 18*30*24*3600,
        penaltyPercentage: 10,
        divideValue: 18*30,
        minStake: 2000,
        maxStake: 1000000,
        poolMax: 20000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0xdB7F6da57b637637eACF57B9781963Ad1bC45c3d",
        fileJson: 'Staking_18.json',
        active: 0
    },
    
    {
        id: 4,
        name: "Platinum Pools",
        date: "14 months",
        lockUp: 14,
        APY: 120,
        dateSecond: 14*30*24*3600,
        penaltyPercentage: 10,
        divideValue: 14*30,
        minStake: 2000,
        maxStake: 1000000,
        poolMax: 20000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0x04b2bA40Bb0DeFB8B95eF97345E68C54b2e5D642",
        fileJson: 'Staking_14.json',
        active: 0
    },
    {
        id: 3,
        name: "Golden Pools",
        date: "12 months",
        lockUp: 12,
        APY: 80,
        dateSecond: 12*30*24*3600,
        penaltyPercentage: 10,
        divideValue: 12*30,
        minStake: 2000,
        maxStake: 750000,
        poolMax: 15000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0x6470F78154c48525e2b1aB2ef3e43132F5762D7A",
        fileJson: 'Staking_12.json',
        active: 0
    },
    {
        id: 2,
        name: "Silver Pools",
        date: "6 months",
        lockUp: 6,
        APY: 35,
        dateSecond: 6*30*24*3600,
        penaltyPercentage: 10,
        divideValue: 6*30,
        minStake: 2000,
        maxStake: 500000,
        poolMax: 10000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0xE51317Eadd4C8c8500144D125165821435d21e5B",
        fileJson: 'Staking_6.json',
        active: 0
    },
   
    {
        id: 1,
        name: "Bronze Pools",
        date: "3 months",
        lockUp: 3,
        APY: 12,
        dateSecond: 3*30*24*3600,
        penaltyPercentage: 10,
        divideValue: 3*30,
        minStake: 2000,
        maxStake: 250000,
        poolMax: 5000000,
        metaToken : "0x9d39eF3bbCA5927909dde44476656B81BBe4eE75",
        stakingToken: "0x46Bda2E267E051C4037d6c872e091F24606f903E",
        fileJson: 'Staking_3.json',
        active: 0
    }     
];
