import React from 'react';

const InstallMetamsk = () => {
    return (
        <div className="bg-cyan-400 border-t-4 border-indigo-600 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
            <div className="flex items-center justify-center gap-x-2">
                <svg className="h-6 w-6 text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                </svg>
                {/*<p className="text-sm text-indigo-900">The contract not deployed to detected network. Select binance test network</p>*/}
                <div className="text-sm text-indigo-900">Please install <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" className="text-indigo-800 underline" target="_blank" rel="noreferrer">Metamask extension</a></div>
            </div>
        </div>
    )
}

export default InstallMetamsk;