import React, { useState, useEffect } from 'react';
import Web3 from "web3";
import NumberFormat from 'react-number-format';

import '../assets/css/style.css';
import contractMetawearBuild from '../contracts/Metawear.json';

//images and svg icons›
import tokenIcon from '../assets/img/loading.svg';
import bgVideo from '../assets/img/bgVideo.mp4';
import SetStakeModal from "./SetStakeModal";
import FindStakerTable from "./FindStakerTable";

const ListStacking = (props) => {
    const {
        walletAccount,
        setWalletAccount,
        setConnectStatus,
        setConnectButtonText,
        metaToken,
        stakingToken,
        packageItem,
        setLoading,
        setLoadingText,
        setLoadingStaking,
        setLoadingClaim,
        setLoadingUnstaking,
        setBalancePackage,
        setIsLogin,
        isLogin,
        loadingData,
        setLoadingData,
        setSubTotalData,
        setNetworkIsTrue,
        myProviderTarget,
        setRefreshTable,
        networkIsTrue,
        successStake,
        setSuccessStakeToast,
        claimUnstakeNotify,
        setSuccessUnstakeToast,
        setShowChangeNetworkAlert,
        totalStaked,
        getCookie,
        setCookie
    } = props

    const StakingJson = require('../contracts/' + packageItem.fileJson);
    const [stakerInfo, setStakerInfo] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [stakeVal, setStakeVal] = useState("");
    const [stakePrice, setStakePrice] = useState("");
    const [unstakeConfirm, setUnstakeConfirm] = useState(false);
    const [packageTotalBalance, setPackageTotalBalance] = useState(0);
    const [packageTotalRewards, setPackageTotalRewards] = useState(0);
    const [tableInlineLoading, setTableInlineLoading] = useState(false);

    let contractStaking;
    let totalBalance = 0;
    let totalRewards = 0;


    const getConnectWallet = () => {
        if (typeof web3 !== 'undefined') {

            let provider = window.web3.currentProvider;
            const web3 = new Web3(provider);

            // Get network Id on load
            web3.eth.net.getId().then((result) => {
                if (result === myProviderTarget) {
                    setNetworkIsTrue(true);
                    setShowChangeNetworkAlert(false)
                    return result;
                } else {
                    setNetworkIsTrue(false);
                    setShowChangeNetworkAlert(true);
                    // setHandleNetworkButton(true);
                }
            })

            // Get network Id
            const createProviderProxy = () => {
                return new Proxy(provider, {});
            }
            const providerProxy = createProviderProxy();
            let providerTarget = Object.assign({}, providerProxy).networkVersion;
            // let myProviderTarget = 97;

            if (providerTarget === myProviderTarget) {
                setNetworkIsTrue(true);
                setShowChangeNetworkAlert(false)
            } else {
                setNetworkIsTrue(false);
                setShowChangeNetworkAlert(true)
            }

            if (provider && provider.isMetaMask) {
                setLoading(true);

                const web3 = new Web3(provider);
                contractStaking = new web3.eth.Contract(
                    StakingJson.abi,
                    stakingToken
                )

                // MetaMask is installed!
                provider.request({ method: 'eth_accounts' })
                    .then(accounts => {
                        setLoading(false);


                        if (getCookie('isLogin') == '0') {
                            setIsLogin(false)
                            setConnectButtonText("");
                            setWalletAccount("");
                        } else {
                            if (accounts.length > 0 && accounts[0] && typeof accounts[0] != 'undefined') {
                                // localStorage.setItem('account', accounts[0]);
                                setCookie('account', accounts[0])
                                setWalletAccount(accounts[0]);
                                setConnectButtonText(`${String(accounts[0]).substring(0, 6)} ... ${String(accounts[0]).substring(38)}`);
                                findStaker(accounts[0]);
                                userBalance(accounts[0]);
                                setIsLogin(true);
                                setNetworkIsTrue(true);
                                setShowChangeNetworkAlert(false);
                                totalStaked();

                            } else {
                                setIsLogin(false)
                                setConnectButtonText("");
                                setWalletAccount("");

                            }
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        if (error.code === 4001) {
                            setConnectStatus('User rejected request');
                            setLoading(false);
                        } else {
                            setConnectStatus(error.message)
                        }
                    })

                // MetaMask account is changed
                provider.on('accountsChanged', (accounts, least) => {
                    contractStaking = new web3.eth.Contract(
                        StakingJson.abi,
                        stakingToken
                    )

                    setSubTotalData([], 'reset');
                    if (accounts.length > 0) {
                        // localStorage.setItem('account', accounts[0])
                        setCookie('account', accounts[0])
                        setWalletAccount(accounts)
                        setConnectButtonText(`${String(accounts[0]).substring(0, 6)} ... ${String(accounts[0]).substring(38)}`)
                        findStaker(accounts[0]);
                        userBalance(accounts[0]);
                        setIsLogin(true);
                        // setNetworkIsTrue(true);
                    }
                    else {
                        setConnectButtonText("");
                        setWalletAccount("");
                        setBalancePackage("");
                        setPackageTotalBalance(0);
                        setPackageTotalRewards(0);
                        setNetworkIsTrue(false);
                    }
                    window.location.reload();

                    setRefreshTable(true);

                });

                // Set connect network when network changed
                provider.on("networkChanged", function (networkId, data) {

                    contractStaking = new web3.eth.Contract(
                        StakingJson.abi,
                        stakingToken
                    )

                    // detectNetwork()
                    setPackageTotalBalance(0);
                    setPackageTotalRewards(0)
                    if (networkId == myProviderTarget) {
                        let accountWallet = getCookie('account');

                        setConnectButtonText(`${String(accountWallet).substring(0, 6)} ... ${String(accountWallet).substring(38)}`);
                        findStaker(accountWallet);
                        userBalance(accountWallet);
                        setIsLogin(true);
                        setNetworkIsTrue(true);
                        setShowChangeNetworkAlert(false)

                    } else {
                        setConnectButtonText('')
                        setNetworkIsTrue(false);
                        setShowChangeNetworkAlert(true);
                        // setHandleNetworkButton(true)
                    }

                    setRefreshTable(true);
                });
            } else {
                return setConnectStatus('Install MetaMask on your browser')
            }


        } else {
            setLoading(false);
            // setHandleNetworkButton(true)
            // return setConnectStatus('Install MetaMask on your browser')
        }
    }

    useEffect(() => {

        getConnectWallet();

    }, [])

    const showStakeModal = () => {
        setIsModalVisible(true);
    };

    const hideStakeModal = () => {
        setIsModalVisible(false);
    };


    const findStaker = (currentUser = '') => {
        if (typeof web3 !== 'undefined') {
            setTableInlineLoading(true);
            const UserWallet = currentUser && currentUser !== '' ? currentUser : walletAccount;
            contractStaking.methods.myStakes(UserWallet)
                .call()
                .then(result => {
                    // console.log('My stakes', result);
                    setLoadingData(true);
                    setTableInlineLoading(false)
                    calculateTotals(result)
                    totalStaked();
                    setStakerInfo(result);
                })
                .catch(err => {
                    setTableInlineLoading(false)
                })
        }
    }

    const stake = async () => {
        if (typeof web3 !== 'undefined') {

            let provider = window.web3.currentProvider;
            const web3 = new Web3(provider);
            let StakeValue = parseInt(stakePrice);
            await getConnectWallet();
            setStakePrice("0");

            if (StakeValue !== '' && StakeValue > 0 && StakeValue >= parseInt(packageItem.minStake)) {
                hideStakeModal();
                setLoadingText(true);
                // StakeValue *= 10**18;
                StakeValue = StakeValue + '000000000000000000';

                approve(StakeValue).then(res => {
                    setLoadingText(false);
                    setLoadingStaking(true);

                    contractStaking = new web3.eth.Contract(
                        StakingJson.abi,
                        stakingToken
                    )

                    contractStaking.methods.stake(StakeValue)
                        .send({ from: walletAccount })
                        .then(result => {

                            setLoadingStaking(false);
                            setSuccessStakeToast(true);
                            successStake();
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000)
                            findStaker();
                            userBalance();
                        })
                        .catch(err => {
                            setLoadingStaking(false);
                        })
                }).catch(err => {
                    setLoadingText(false);
                });
            }

        }
    }

    const approve = (amount) => {
        if (typeof web3 !== 'undefined') {
            let provider = window.web3.currentProvider;

            const web3 = new Web3(provider);

            let contractToken = new web3.eth.Contract(
                contractMetawearBuild.abi,
                metaToken
            )

            return contractToken.methods.approve(stakingToken, amount)
                .send({ from: walletAccount });
        }
    }

    const userBalance = (currentUser = '') => {

        if (typeof web3 !== 'undefined') {
            let provider = window.web3.currentProvider;

            const web3 = new Web3(provider);

            const UserWallet = currentUser && currentUser !== '' ? currentUser : walletAccount;

            contractStaking = new web3.eth.Contract(
                contractMetawearBuild.abi,
                metaToken
            )

            contractStaking.methods.balanceOf(UserWallet)
                .call({ from: walletAccount })
                .then(result => {
                    setBalancePackage((parseInt(result) / (10 ** 18)).toFixed());
                })
        }
    }

    const convertTime = (timeStamp) => {
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const date = new Date(timeStamp * 1000);
        const dateMinState = (date.getMinutes() < 10 ? '0' : '');
        const dateSecState = (date.getSeconds() < 10 ? '0' : '');
        let date_ = date.getDate() + "-" + month[date.getMonth()] + "-" + date.getFullYear() + " " + date.getHours() + ":" + dateMinState + date.getMinutes() + ":" + dateSecState + date.getSeconds();
        return date_;
    }

    const calculateTotals = (result) => {

        if (result && result.length) {
            let RewardsAll = 0;
            for (let i = 0; i < result.length; i++) {
                let item = result[i];
                let amount = parseFloat(item.amount);
                let claimable = parseFloat(item.claimable ? item.claimable : item.reward ? item.reward : 0);

                let rewaard_;
                totalBalance += amount / (10 ** 18);
                rewaard_ = claimable / (10**18)
                totalRewards += rewaard_;

                if (StakeUnstake(packageItem.dateSecond, item.stakedAt) === 1) {
                    RewardsAll += rewaard_;
                }

            }
            setSubTotalData([packageItem.id, RewardsAll], 'earnings');
            totalBalance = Math.round(parseFloat(totalBalance) * 100, 2) / 100;

            setPackageTotalBalance(totalBalance)
            setPackageTotalRewards(totalRewards)
        } else {
            setPackageTotalBalance(0)
            setPackageTotalRewards(0)
        }
    }

    const stakeReward = (deposit, APY, lockUp, claimedAt = null, dateSecond = null) => {
        if (lockUp !== false) {
            deposit /= 10 ** 18;
            let reward = deposit + (deposit * APY / 100);
            return reward.toFixed(2);
        } else {
            let _now = Math.floor(Date.now() / 1000);
            let hour = Math.floor((_now - claimedAt) / dateSecond);
            deposit /= 10 ** 18;

            return (deposit * APY * hour).toFixed(1);
        }
    }

    const stakePenalty = (amount, stakeTime, dateSecond, penaltyPercent, divideValue) => {
        amount /= 10 ** 18;
        stakeTime = parseInt(stakeTime);
        dateSecond = parseInt(dateSecond);

        const currentTime = Math.round(new Date().getTime() / 1000);
        let stakeYetSecond = (stakeTime + dateSecond) - currentTime;

        if (stakeYetSecond <= 0) {
            return 0;
        }

        let periodTime = dateSecond / divideValue;
        let periodPercent = penaltyPercent / divideValue;
        let remainingPeriodTime = stakeYetSecond / periodTime;
        remainingPeriodTime = parseInt(remainingPeriodTime) == remainingPeriodTime ? remainingPeriodTime : parseInt(remainingPeriodTime) + 1;
        let remainingTotal = (remainingPeriodTime * periodPercent * amount) / 100;

        return parseInt(remainingTotal);
    }

    const stakeStablePenalty = (amount, penaltyPercent) => {
        let remainingTotal;

        amount /= 10 ** 18;

        remainingTotal = amount * penaltyPercent / 100;

        return parseInt(remainingTotal);
    }

    const remainingDays = (stakeTime, dateSecond) => {
        const currentTime = Math.round(new Date().getTime() / 1000);
        stakeTime = parseInt(stakeTime)
        dateSecond = parseInt(dateSecond)
        let diffday = (stakeTime + dateSecond) - currentTime
        return (diffday / (24 * 3600)).toFixed();
    }

    const claim = (timeStamp, type_) => {

        if (typeof web3 !== 'undefined') {

            const web3 = new Web3(window.web3.currentProvider);
            contractStaking = new web3.eth.Contract(
                StakingJson.abi,
                stakingToken
            )

            const currentTime = Math.round(new Date().getTime() / 1000);
            const StakeInterval = currentTime - timeStamp - packageItem.dateSecond;

            if (StakeInterval > 0) {
                setLoadingClaim(true);
            } else {
                setLoadingUnstaking(true);
            }
            setUnstakeConfirm(false);

            contractStaking.methods.claim(timeStamp) // stakedAt value
                .send({ from: walletAccount })
                .then(result => {
                    setLoadingClaim(false);
                    setLoadingUnstaking(false);
                    setSuccessUnstakeToast(type_)
                    claimUnstakeNotify()
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                    findStaker();
                    userBalance();
                })
                .catch(err => {
                    setLoadingClaim(false);
                    setLoadingUnstaking(false);
                })
        }
    }

    const claimAll = (timeStamp, type_) => {
        if (typeof web3 !== 'undefined') {

            const web3 = new Web3(window.web3.currentProvider);
            contractStaking = new web3.eth.Contract(
                StakingJson.abi,
                stakingToken
            )

            const currentTime = Math.round(new Date().getTime() / 1000);
            const StakeInterval = currentTime - timeStamp - packageItem.dateSecond;

            if (StakeInterval > 0) {
                setLoadingClaim(true);
            } else {
                setLoadingUnstaking(true);
            }
            setUnstakeConfirm(false);

            contractStaking.methods.claimAll(timeStamp)
                .send({ from: walletAccount })
                .then(result => {
                    setLoadingClaim(false);
                    setLoadingUnstaking(false);
                    setSuccessUnstakeToast(type_)
                    claimUnstakeNotify()
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                    findStaker();
                    userBalance();
                })
                .catch(err => {
                    setLoadingClaim(false);
                    setLoadingUnstaking(false);
                })
        }
    }

    const StakeUnstake = (interval, timestamp) => {
        timestamp = parseInt(timestamp);
        interval = parseInt(interval);

        const currentTime = Math.round(new Date().getTime() / 1000);
        const StakeInterval = (timestamp + interval) - currentTime;
        let typeR = 1;
        if (StakeInterval > 0) {
            typeR = 0;
        }
        return typeR;
    }

    const showHideButtons = (type, interval, timestamp) => {
        if (StakeUnstake(interval, timestamp) === 1) {
            if (type === 1) { // time not end yet - unstake
                //     enable
                return 'hidden';
            } else {
                //    disable
                return '';
            }
        } else {
            if (type === 1) {
                //     enable
                return '';
            } else {
                //    disable
                return 'hidden';
            }
        }
    }

    return (
        <>
            <div className="mb-6 rounded-md overflow-hidden" id="stake">
                <div className={`flex items-center gap-x-6 justify-between px-10 py-6 rounded-md main-bg-color-900`} style={{position: 'relative'}}>
                    <div className="flex items-center gap-x-6 flex-1">
                        <div className="flex flex-col gap-y-1 items-center w-1/6">
                            {packageItem.id == '14' ?
                            <div style={{position: 'absolute', top: '0', left: '0', bottom: '0'}}>
                                <video autoPlay muted loop style={{width: '75%'}}>
                                    <source src={bgVideo} type='video/mp4'></source>
                                </video>
                            </div> 
                            : ""}
                            <h2 className="text-white text-lg font-medium uppercase pool-name" style={{zIndex: '999'}}>{packageItem.name}</h2>
                            {packageItem.active == 1 ? <span className="text-cyan-500" style={{zIndex: '999'}}>Available <i style={packageItem.lockUp !== false ? { color: '#d2406d' } : { color: '#fff' }}>(Limited)</i></span> : <span className="text-rose-500" style={{zIndex: '999'}}>closed</span>}
                        </div>
                        <div className="flex flex-col gap-y-5 items-center w-1/6">
                            <h2 className="text-white text-xl font-medium">Period</h2>
                            <span className="text-white text-base font-light">{packageItem.date}</span>
                        </div>
                        {packageItem.lockUp !== false ?
                            <div className="flex flex-col gap-y-5 items-center w-1/6">
                                <h2 className="text-white text-xl font-medium">Income Rate</h2>
                                <span className="text-white text-base font-light">{packageItem.APY}%</span>
                            </div>
                            :
                            <div className="flex flex-col gap-y-5 items-center w-1/6">
                                <h2 className="text-white text-xl font-medium">Income Rate</h2>
                                <span className="text-white text-base font-light">{packageItem.apyName}</span>
                            </div>
                        }
                        <div className="flex flex-col gap-y-5 items-center w-1/6">
                            <h2 className="text-white text-xl font-medium">Balance</h2>
                            {packageTotalBalance > 0 && isLogin && networkIsTrue ?
                                <div className="flex items-center gap-x-1">
                                    <img src={tokenIcon} alt="WEAR" className="w-5" />
                                    <NumberFormat
                                        value={packageTotalBalance}
                                        className="text-white text-base font-light"
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div> : '-'
                            }
                        </div>
                        <div className="flex flex-col gap-y-5 items-center w-1/6">
                            <h2 className="text-white text-xl font-medium">{packageItem.lockUp !== false ? 'Total Return' : 'Claimable'}</h2>
                            {((packageItem.lockUp === false && packageTotalRewards > 0) || packageTotalRewards > 0) && isLogin && networkIsTrue ?
                                <div className="flex items-center gap-x-1">
                                    <img src={tokenIcon} alt="WEAR" className="w-5" />
                                    <NumberFormat
                                        value={packageTotalRewards}
                                        className="text-white text-base font-light"
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div> : '-'
                            }
                        </div>
                    </div>

                    <button
                        disabled={packageItem.active & isLogin && networkIsTrue ? '' : 'disabled'}

                        className={`text-base py-1.5 px-7 rounded-md focus:outline-none focus:shadow-outline text-indigo-900 ${packageItem.active && isLogin && networkIsTrue ? 'bg-cyan-500 opacity-90 hover:bg-cyan-600' : 'text-white opacity-50 main-bg-color-300'}`}
                        onClick={() => showStakeModal()}
                        type="button">Stake</button>
                </div>

                <FindStakerTable
                    showHideButtons={showHideButtons}
                    stakeReward={stakeReward}
                    stakePenalty={stakePenalty}
                    stakeStablePenalty={stakeStablePenalty}
                    claim={claim}
                    claimAll={claimAll}
                    convertTime={convertTime}
                    packageItem={packageItem}
                    unstakeConfirm={unstakeConfirm}
                    setUnstakeConfirm={setUnstakeConfirm}
                    walletAccount={walletAccount}
                    stakerInfo={stakerInfo}
                    loadingData={loadingData}
                    remainingDays={remainingDays}
                    isLogin={isLogin}
                    networkIsTrue={networkIsTrue}
                    tableInlineLoading={tableInlineLoading}
                />
                {isModalVisible ?
                    <SetStakeModal
                        hideStakeModal={hideStakeModal}
                        stake={stake}
                        minStake={packageItem.minStake}
                        maxStake={packageItem.maxStake}
                        id={packageItem.id}
                        setStakeVal={setStakeVal}
                        stakeVal={stakeVal}
                        setStakPrice={setStakePrice}
                        stakeReward={stakeReward}
                        packageItem={packageItem}
                    /> : ''
                }
            </div>
        </>
    );
}

export default ListStacking;
