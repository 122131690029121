import React from 'react';

const SubjectLink = (props) => {
    const {title} = props;
    return (
        <div className="flex flex-col gap-y-2 mb-10 mt-20">
            <h1 className="text-3xl text-white font-bold opacity-90">{title}</h1>
            <p className="text-slate-400">If you don’t see an answer to your questions below, please, <a href="https://t.me/metaweartoken" target="_blank" rel="noreferrer" className="underline text-cyan-500">join our community</a> and let us know.</p>
        </div>
    )
}

export default SubjectLink;