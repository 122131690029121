import React, {useState, useEffect} from 'react';
import {successStake, claimUnstakeNotify} from "./components/Alerts/Alerts";
import {ToastContainer} from 'react-toastify';
import {PackagesData} from "./components/Package/PackagesData";
import {PackagesDataOld} from './components/Package/PackagesDataOld';

import Header from "./components/Header";
import ListStacking from "./components/ListStacking";
import Loading from "./components/Loading";
import LoadingText from "./components/LoadingText";
import logo from "./assets/img/wearlogo-3.png";
import bannerImg from "./assets/img/banner-2.jpg";
import GetWear from "./components/GetWear";
import Subject from "./components/Subject";
import Accordion from "./components/Accordion";
import Web3 from "web3";
import SubjectLink from "./components/SubjectLink";
import ChangeNetwork from "./components/Alerts/ChangeNetwork";
import Footer from "./components/Footer";
import InstallMetamsk from "./components/Alerts/InstallMetamask";

const App = () => {

    const [walletAccount, setWalletAccount] = useState("");
    const [connectStatus, setConnectStatus] = useState("");
    const [connectButtonText, setConnectButtonText] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState(false);
    const [loadingStaking, setLoadingStaking] = useState(false);
    const [loadingUnstaking, setLoadingUnstaking] = useState(false);
    const [loadingClaim, setLoadingClaim] = useState(false);
    const [balancePackage, setBalancePackage] = useState("");
    const [isLogin, setIsLogin] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [SubTotalValueLocked, setSubTotalValueLocked] = useState(0);
    const [SubTotalBalance, setSubTotalBalance] = useState(0);
    const [SubTotalEarnings, setSubTotalEarnings] = useState(0);
    const [networkIsTrue, setNetworkIsTrue] = useState(true);
    const [refreshTable, setRefreshTable] = useState(true);
    const [showMetamask, setShowMetamask] = useState(false);
    const [showChangeNetworkAlert, setShowChangeNetworkAlert] = useState(false);
    const [successStakeToast, setSuccessStakeToast] = useState(false);
    const [successUnstakeToast, setSuccessUnstakeToast] = useState(0);
    const [isDemo, setIsDemo] = useState(false);

    const switchDemo = () => {
        if (window.location.href.split('/').pop() == 'demo'){
            return setIsDemo(false);
        }
        return setIsDemo(false);
    }

    // let myProviderTarget = 97;
    let myProviderTarget = 56;
    let TotalData = {balance : [], earnings: [], value_locked:[]};

    const  setSubTotalData = (arr, type) => {
        let breaking=false;

        if (type == 'reset') {
            TotalData = {balance : [], earnings: [], value_locked:[]};
            setSubTotalBalance(0);
            setSubTotalEarnings(0);
            setSubTotalValueLocked(0);
            return ;
        }

        for (let i=0; i < TotalData[type].length; i++) {
            if (TotalData[type][i][0] == arr[0]) {
                breaking = true;
                break;
            }
        }

        if (!breaking) {
            TotalData[type].push(arr);
        }

        let Balance = 0;

        for (let i=0; i < TotalData[type].length; i++) {
            Balance += TotalData[type][i][1];
        }

        if (type == 'balance') {
            setSubTotalBalance(Balance);
        } else if (type == 'earnings') {
            setSubTotalEarnings(Balance);
        } else if (type == 'value_locked') {
            setSubTotalValueLocked(Balance);
        }
    }

    const connectWalletHandler = () => {

        if (typeof  window.web3 != 'undefined' && typeof  window.web3.currentProvider != 'undefined' ) {

            let provider = window.web3.currentProvider;

            if( provider && provider.isMetaMask) {

                setCookie ('isLogin', 1)

              // MetaMask is installed!
              provider.request({method: 'eth_requestAccounts'})
                  .then(accounts => {

                      setIsLogin(true);
                      setNetworkIsTrue(true);
                      setCookie ("account", accounts[0])

                      // if(localStorage.getItem('isLogin') === '1') {
                        setWalletAccount(accounts[0]);
                        setConnectButtonText(`${String(accounts[0]).substring(0, 6)} ... ${String(accounts[0]).substring(38)}`);
                    // }
                  })
                  .catch(error => {
                    if (error.code === 4001) {
                      setConnectStatus('User rejected request');
                    } else if(error.code === -32002) {
                      setConnectStatus('User request already processing');
                    }
                    else {
                      setConnectStatus(error.message)
                    }

                      setConnectButtonText('')
                  })
            }
        }
    }

    const switchNetwork = async () => {

        if (typeof window.web3 != 'undefined' && typeof window.web3.currentProvider != 'undefined') {
            let provider = window.web3.currentProvider;
            const web3 = new Web3(provider);
            // const chainIdHex = web3.currentProvider.chainId;
            // const chainIdDec = await web3.eth.getChainId();

            provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: `0x${Number(56).toString(16)}`}]
            }).then((result) => {
                setNetworkIsTrue(true)
                setShowChangeNetworkAlert(false)
                window.location.reload()
            })
            .catch (error => {
                setNetworkIsTrue(false);

                if (error.code === 4902) {
                    provider.request({
                        method: 'wallet_addEthereumChain',
                        // params: [{chainId: `0x${Number(56).toString(16)}`}]
                        params: [
                            {
                                chainId: "0x38",
                                chainName: "Smart Chain",
                                rpcUrls: ["https://bsc-dataseed.binance.org/"],
                                blockExplorerUrls: ["https://bscscan.com"],
                                nativeCurrency: {
                                    symbol: "BNB",
                                    decimals: 18,
                                },
                            },
                        ]
                    }).then( () => {
                        // setHandleNetworkButton(false);
                        setNetworkIsTrue(true);
                    })
                }
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
        } else {
            setIsLogin(false)
        }

        if (typeof navigator.brave != 'undefined' && navigator.brave && navigator.brave.isBrave()) {
            setShowMetamask(true)
        }
    }

    useEffect(() => {

        totalStaked();

        switchDemo();

        setTimeout(() => {
            setLoading(false)
        }, 4000)

        if ((typeof window.ethereum == 'undefined')) {
            setShowMetamask(true)
        }

    }, [])

    const disconnectWalletHandler = () => {
        if (typeof window.web3 != 'undefined' && typeof window.web3.currentProvider != 'undefined') {
            let provider = window.web3.currentProvider;
            if (provider && provider.isMetaMask) {
                setIsLogin(false)
                setCookie ("isLogin", 0)


                provider.request({method: 'eth_requestAccounts'})
                .then(() => {
                    setWalletAccount("");
                    setConnectButtonText("");
                })
            }
        }
    }

    const getCookie = (cookie_) => {
        let Cookies = document.cookie;
        if(Cookies != '' && Cookies.indexOf(cookie_ + '=') > -1) {
            let SpCookie = Cookies.split(cookie_ + '=');
            let Cookie = SpCookie[1].split(';')[0];
            return Cookie;
        }

        return '';
    }

    const setCookie = (cookie_, val_) => {
        document.cookie = cookie_ + "=" + val_ + "; expires=Thu, 18 Dec 2022 12:00:00 UTC; path=/";
        return true;
    }



    const totalStaked = () => {
        if(typeof web3 !== 'undefined') {
            PackagesData.map(packageItem => {
                const StakingJson = require('./contracts/'+packageItem.fileJson);

                const web3 = new Web3(window.web3.currentProvider);
                let contractStaking = new web3.eth.Contract(
                    StakingJson.abi,
                    packageItem.stakingToken
                )

                contractStaking.methods.totalStaked().call(function(err, totalBalance) {
                    totalBalance = parseInt(totalBalance)/(10**18);
                    setSubTotalData([packageItem.id, totalBalance], 'balance');
                });
            })
        }
    }

    return (
        <div className="font-sans text-xs text-white min-h-screen main-bg-color-600">
            {loading ? <Loading /> : ''}
            {loadingText ? <LoadingText text="Wait for approval" /> : ''}
            {loadingStaking ? <LoadingText text="Wait for staking" /> : ''}
            {loadingClaim ? <LoadingText text="Wait for claiming" /> : ''}
            {loadingUnstaking ? <LoadingText text="Wait for unstaking" /> : ''}
            <Header
                logo={logo}
                disconnectWalletHandler={disconnectWalletHandler}
                connectWalletHandler={connectWalletHandler}
                connectButtonText={connectButtonText}
                balancePackage={balancePackage}
                walletAccount={walletAccount}
                switchNetwork={switchNetwork}
                isLogin={isLogin}
                networkIsTrue={networkIsTrue}
            />
            {showMetamask ? <InstallMetamsk /> : '' }
            {showChangeNetworkAlert ? <ChangeNetwork /> : ''}
            {successStakeToast ?
                <ToastContainer
                    position="top-center"
                    theme="colored"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />: ''}
            {successUnstakeToast == 1 ?
                <ToastContainer
                    position="top-center"
                    theme="colored"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />: ''}
            {successUnstakeToast == 2 ?
                <ToastContainer
                    position="top-center"
                    theme="colored"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />: ''}

            <div className="container mx-auto px-16 py-8 main-bg-color-600">
                <img src={bannerImg} />
                <Subject
                    title="Get $WEAR"
                    subTitle=" $WEAR is the native currency of the Metawear ecosystem and will be used for transactions, NFTs, assets, events, services, and more."
                />
                <GetWear
                    balancePackage={balancePackage}
                    SubTotalBalance={SubTotalBalance} SubTotalEarnings={SubTotalEarnings}
                    walletAccount={walletAccount}
                    networkIsTrue={networkIsTrue}
                    isLogin={isLogin}
                />
                <Subject
                    title="$WEAR Staking"
                    subTitle="Stake your way to rewards. The longer the staking pool/period is,  the higher rewards you receive. As in all staking platform pools, premature un-staking will cause loss of accumulated rewards and correspondent penalty fee. (See FAQ)"
                    marginTopClassName='mt-10'
                />

                {false ? 
                    refreshTable || isLogin ?
                        PackagesData.map((packageItem) => {
                            return (
                                <ListStacking
                                    getCookie={getCookie}
                                    setCookie={setCookie}
                                    key={packageItem.id}
                                    metaToken={packageItem.metaToken}
                                    stakingToken={packageItem.stakingToken}
                                    walletAccount={walletAccount}
                                    setWalletAccount={setWalletAccount}
                                    connectStatus={connectStatus}
                                    setConnectStatus={setConnectStatus}
                                    setConnectButtonText={setConnectButtonText}
                                    packageItem={packageItem}
                                    setLoading={setLoading}
                                    setLoadingText={setLoadingText}
                                    setLoadingStaking={setLoadingStaking}
                                    setLoadingClaim={setLoadingClaim}
                                    setBalancePackage={setBalancePackage}
                                    balancePackage={balancePackage}
                                    isLogin={isLogin}
                                    setIsLogin={setIsLogin}
                                    loadingData={loadingData}
                                    setLoadingData={setLoadingData}
                                    setLoadingUnstaking={setLoadingUnstaking}
                                    setSubTotalData={setSubTotalData}
                                    setNetworkIsTrue={setNetworkIsTrue}
                                    myProviderTarget={myProviderTarget}
                                    setRefreshTable={setRefreshTable}
                                    networkIsTrue={networkIsTrue}
                                    refreshTable={refreshTable}
                                    setShowChangeNetworkAlert={setShowChangeNetworkAlert}
                                    successStake={successStake}
                                    setSuccessStakeToast={setSuccessStakeToast}
                                    claimUnstakeNotify={claimUnstakeNotify}
                                    setSuccessUnstakeToast={setSuccessUnstakeToast}
                                    totalStaked={totalStaked}
                                />
                            )

                        }) 
                        : ""
                        : PackagesDataOld.map((packageItem) => {
                            return (
                                <ListStacking
                                    getCookie={getCookie}
                                    setCookie={setCookie}
                                    key={packageItem.id}
                                    metaToken={packageItem.metaToken}
                                    stakingToken={packageItem.stakingToken}
                                    walletAccount={walletAccount}
                                    setWalletAccount={setWalletAccount}
                                    connectStatus={connectStatus}
                                    setConnectStatus={setConnectStatus}
                                    setConnectButtonText={setConnectButtonText}
                                    packageItem={packageItem}
                                    setLoading={setLoading}
                                    setLoadingText={setLoadingText}
                                    setLoadingStaking={setLoadingStaking}
                                    setLoadingClaim={setLoadingClaim}
                                    setBalancePackage={setBalancePackage}
                                    balancePackage={balancePackage}
                                    isLogin={isLogin}
                                    setIsLogin={setIsLogin}
                                    loadingData={loadingData}
                                    setLoadingData={setLoadingData}
                                    setLoadingUnstaking={setLoadingUnstaking}
                                    setSubTotalData={setSubTotalData}
                                    setNetworkIsTrue={setNetworkIsTrue}
                                    myProviderTarget={myProviderTarget}
                                    setRefreshTable={setRefreshTable}
                                    networkIsTrue={networkIsTrue}
                                    refreshTable={refreshTable}
                                    setShowChangeNetworkAlert={setShowChangeNetworkAlert}
                                    successStake={successStake}
                                    setSuccessStakeToast={setSuccessStakeToast}
                                    claimUnstakeNotify={claimUnstakeNotify}
                                    setSuccessUnstakeToast={setSuccessUnstakeToast}
                                    totalStaked={totalStaked}
                                />
                            )
        
                        })
                }
                <SubjectLink title="FAQ" />
                <Accordion />
            </div>
            <Footer />
        </div>
    );
}

export default App;
