import React, {useState, useEffect} from 'react';
import NumberFormat from "react-number-format";
import { isMobile, isChrome, isBrowser, isChromium, isDesktop } from "react-device-detect";
import {useDesktop} from "../components/Alerts/Alerts";
import {ToastContainer} from "react-toastify";

const Header = ({
        logo,
        connectWalletHandler,
        connectButtonText,
        balancePackage,
        disconnectWalletHandler,
        walletAccount,
        switchNetwork,
        isLogin,
        networkIsTrue,
    }) => {

    // const [useDesktopAlert, setUseDesktopAlert] = useState(false);
    // const ShowModal = () => {
    //     setUseDesktopAlert(true);
    //     useDesktop()
    // };

    // const  detectBrowser = () => {
    //     return navigator.userAgent;
    //     if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
    //         return 'Opera';
    //     } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
    //         return 'Chrome';
    //     } else if(navigator.userAgent.indexOf("Safari") != -1) {
    //         return 'Safari';
    //     } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
    //         return 'Firefox';
    //     } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
    //         return 'IE';//crap
    //     } else {
    //         return 'Unknown';
    //     }
    // }



    return (
        <div className="py-5 main-bg-color-900">
            {/*{detectBrowser()} <br/>*/}
        {/*ethereum - {window.ethereum && typeof window.ethereum != 'undefined' && window.ethereum.isMetaMask ? 1:0} "isMobile-{isMobile?1:0}, isChrome-{isChrome?1:0}, isBrowser-{isBrowser?1:0}, isChromium-{isChromium?1:0}, isDesktop-{isDesktop?1:0}"*/}
            <div className="flex justify-between items-center container mx-auto px-4">
                <img src={logo} className="w-auto" alt="logo" />
                <div className="flex items-center gap-x-6">
                    { walletAccount !=='' ?
                        <div className="flex items-center gap-x-2">
                            { isLogin && networkIsTrue ?
                            <NumberFormat
                                value={balancePackage}
                                className="text-zinc-300 text-sm"
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={(value, props) =><span {...props}>Balance {value}</span>}
                            /> : ''}
                        </div>
                        : '' }
                    <div className="flex items-center gap-x-2">
                        {isLogin ?
                            networkIsTrue ?
                            <button
                                onClick={disconnectWalletHandler}
                                className="button--gradient focus:outline-none text-sm text-white py-1 px-4 rounded-2xl uppercase">
                                    Disconnect
                            </button>
                            :
                            <button
                                onClick={switchNetwork}
                                className={`flex items-center gap-x-2 button--gradient focus:outline-none text-sm text-white py-1 px-4 rounded-2xl uppercase`}>
                                <svg className="h-6 w-6 text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke="white" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                                </svg> Network
                            </button>
                        : isMobile ?
                            window.ethereum && typeof window.ethereum != 'undefined' && window.ethereum.isMetaMask ?
                            <button
                                onClick={connectWalletHandler}
                                className="button--gradient focus:outline-none text-sm text-white py-1 px-4 rounded-2xl uppercase">
                                Connect Wallet
                            </button>
                            :
                            <a href="https://metamask.app.link/dapp/app.metawear.com"
                                className="button--gradient focus:outline-none text-sm text-white py-1 px-4 rounded-2xl uppercase">
                                Connect Wallet
                            </a>
                        :
                        <button
                            onClick={connectWalletHandler}
                            className="button--gradient focus:outline-none text-sm text-white py-1 px-4 rounded-2xl uppercase">
                            Connect Wallet
                        </button>
                        }
                    </div>
                    <div className="flex items-center gap-x-2">
                        {isLogin && networkIsTrue ? <span className="text-sm text-zinc-300">{connectButtonText}</span> : ''}
                    </div>
                </div>
            </div>
            {/*{useDesktopAlert ?*/}
            {/*    <ToastContainer*/}
            {/*        position="top-right"*/}
            {/*        theme="colored"*/}
            {/*        autoClose={5000}*/}
            {/*        hideProgressBar={false}*/}
            {/*        newestOnTop={false}*/}
            {/*        closeOnClick*/}
            {/*        rtl={false}*/}
            {/*        pauseOnFocusLoss*/}
            {/*        draggable*/}
            {/*        pauseOnHover*/}
            {/*    /> : ''*/}
            {/*}*/}
        </div>
    )
}

export default Header;